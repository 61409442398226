import React from "react";
import {
    Button,
    Dropdown,
    Menu,
    Upload,
    message,
    Tooltip,
    Typography,
    Badge,
    Alert,
} from "antd";
import { FormatMoney } from "format-money-js";
import {
    CheckCircleFilled,
    CloseCircleFilled,
    DollarCircleFilled,
    PayCircleFilled,
    DownOutlined,
    ExclamationCircleFilled,
    InfoCircleFilled,
    QuestionCircleFilled,
    StopFilled,
    WarningFilled,
    FileTextOutlined,
    FileFilled,
    ExpandAltOutlined,
    CopyFilled,
} from "@ant-design/icons";
import { conditionsMatched } from "./conditionsMatcher";
import { Link } from "react-router-dom";
import { isArray } from "lodash";
import { FileIcons } from "../components/displays/FilesRefDisplay";
import TextTruncate from "react-text-truncate";
import moment from "moment";
import ReactJson from "react-json-view";
import presentModal from "../components/presentModal";
import DeviceLogViewer from "../components/displays/custom/DeviceLogViewer";

const { Text } = Typography;

function renderActions(
    actions,
    executor,
    conditionState = {},
    visibleMax = 1,
    buttonProps = { type: "link" }
) {
    const visibleActions = actions.filter(({ showCondition }) => {
        if (!showCondition) {
            return true;
        }
        return conditionsMatched(showCondition, conditionState);
    });

    const visibleButtons = [];
    const dropdownMenuItems = [];

    let cursor = 0;

    const needDropdown = visibleActions.length > visibleMax;

    while (cursor < visibleActions.length) {
        const action = visibleActions[cursor];
        const { id, title } = action;

        action.fixedState = conditionState;
        let btn;
        if (action.type === 11) {
            const uploadProps = {
                ...action.uploadProps,
                onChange({ file }) {
                    if (file.status === "uploading") {
                        message.loading({ content: title + "...", key: id });
                    } else if (file.status === "done") {
                        const response = file.response;
                        if (!response) {
                            message.error({
                                content: `${action.title} 失败`,
                                key: id,
                            });
                            return;
                        }
                        if (response.code === 0) {
                            message.success({
                                content: `${action.title} 成功`,
                                key: id,
                            });
                        } else {
                            message.error({
                                content: `${action.title} 失败:${response.desc}`,
                                key: id,
                            });
                        }
                    } else if (file.status === "error") {
                        message.error({
                            content: `${action.title} 失败`,
                            key: id,
                        });
                    }
                },
            };
            buttonProps.type = "default";
            btn = (
                <Upload {...uploadProps} key={cursor}>
                    <Button style={{ marginRight: 10 }} {...buttonProps}>
                        {title.toUpperCase()}
                    </Button>
                </Upload>
            );
        } else {
            if (buttonProps.type === "link") {
                btn = (
                    <a
                        size="small"
                        style={{ marginRight: 6 }}
                        onClick={executor.generateActionHandler(action)}
                        key={cursor}
                        {...buttonProps}
                    >
                        {title.toUpperCase()}
                    </a>
                );
            } else {
                buttonProps.type =
                    action.type === 2 ||
                    action.type === 4 ||
                    action.type === 13 ||
                    action.type === 5
                        ? "primary"
                        : "default";
                btn = (
                    <Button
                        style={{ marginRight: 6 }}
                        size={"middle"}
                        onClick={executor.generateActionHandler(action)}
                        key={cursor}
                        {...buttonProps}
                    >
                        {title.toUpperCase()}
                    </Button>
                );
            }
        }

        if (cursor < visibleMax - 1 || !needDropdown) {
            visibleButtons.push(btn);
        } else {
            dropdownMenuItems.push(<Menu.Item key={id}>{btn}</Menu.Item>);
        }

        cursor += 1;
    }

    if (dropdownMenuItems.length > 0) {
        const dropDownMenu = <Menu>{dropdownMenuItems}</Menu>;
        visibleButtons.push(
            <Dropdown key={cursor} overlay={dropDownMenu} trigger={["click"]}>
                <a size="small" type="link" style={{ padding: 0 }}>
                    更多
                    <DownOutlined />
                </a>
            </Dropdown>
        );
    }

    return visibleButtons;
}

function renderActionWith(action, executor, conditionState = {}, renderButton) {
    const { id, title, showCondition } = action;
    const visible =
        !showCondition || conditionsMatched(showCondition, conditionState);
    if (!visible) return undefined;
    action.fixedState = conditionState;
    return renderButton(title, action, executor.generateActionHandler(action));
}

function prepareBatchAction(action, items) {
    if (!action.batchParamKeys || action.batchParamKeys.length === 0) {
        return;
    }

    let fixedParams = action.params || action.editor.fixed;

    for (let batchKey in action.batchParamKeys) {
        const itemKey = action.batchParamKeys[batchKey];
        fixedParams[batchKey] = items.map(item => item[itemKey]).join(",");
    }

    // todo: batched operations must provide id as operation arg
    if (action.params) {
        action.params = fixedParams;
    } else if (action.editor) {
        action.editor.fixed = fixedParams;
    }
    action.fixedState = {};

    if (action.confirm) {
        action.confirm = `确认对选中的 ${items.length} 项进行 "${action.title}" 操作？`;
    }
}

function renderBatchItemActions(actions, executor, items, actionTargets) {
    const visibleButtons = [];

    for (let action of actions) {
        const { id, title } = action;

        const targetIds = actionTargets[id];

        let allowed = true;
        for (let item of items) {
            if (targetIds.indexOf(item.id) < 0) {
                allowed = false;
                break;
            }
        }

        allowed && prepareBatchAction(action, items);
        visibleButtons.push(
            <Button
                disabled={!allowed || items.length === 0}
                style={{ marginRight: 8 }}
                onClick={executor.generateActionHandler(action)}
                key={id}
            >
                {title.toUpperCase()}
            </Button>
        );
    }

    return visibleButtons;
}

function renderItemFieldValue(
    displayField,
    displayedItem,
    nolink = false,
    actionExecutor = null,
    isDetail = false
) {
    let { style, key, extra_props = {} } = displayField;
    if (!extra_props) extra_props = {};
    const fieldValue =
        displayedItem[key] != null && displayedItem[key].length >= 800
            ? displayedItem[key].substring(0, 800) + "..."
            : displayedItem[key];
    const rawValues = displayedItem._raws_ || displayedItem;
    if (extra_props.hasOwnProperty("_delete_row_")) {
        if (
            extra_props._delete_row_ === rawValues[key] ||
            (extra_props._delete_row_.split &&
                extra_props._delete_row_.split(",").indexOf(rawValues[key]) >=
                    0)
        ) {
            if (!displayedItem.rowClassName) {
                displayedItem.rowClassName = "table-delete-row";
            } else {
                if (
                    displayedItem.rowClassName.indexOf("table-delete-row") < 0
                ) {
                    displayedItem.rowClassName += " table-delete-row";
                }
            }
        }
    }
    let color = undefined;
    if (extra_props["color_condition"] && extra_props["condition_color"]) {
        if (conditionsMatched(extra_props["color_condition"], rawValues)) {
            color = extra_props["condition_color"];
        } else if (extra_props["value_color"]) {
            color = extra_props["value_color"];
        }
    } else {
        color = extra_props["value_color"];
    }
    let prefix = "";
    let surfix = "";
    if (extra_props["prefix"]) {
        prefix = extra_props["prefix"];
    }
    if (extra_props["surfix"]) {
        surfix = extra_props["surfix"];
    }

    const mainContent = content(
        fieldValue,
        displayField,
        displayedItem,
        color,
        nolink,
        actionExecutor,
        isDetail
    );

    let hintString = undefined;
    if (extra_props.ellipsis) {
        hintString =
            displayedItem[key] != null && displayedItem[key].length >= 10
                ? displayedItem[key]
                : "";
    } else if (
        extra_props.hint_key &&
        (displayedItem[extra_props.hint_key] || rawValues[extra_props.hint_key])
    ) {
        hintString =
            displayedItem[extra_props.hint_key] ||
            rawValues[extra_props.hint_key];
    } else if (extra_props.hint_value) {
        hintString = extra_props.hint_value;
    }
    if (hintString) {
        let hint_prefix = "";
        let hint_surfix = "";
        let hint_icon = "";
        if (extra_props["hint_prefix"]) {
            hint_prefix = extra_props["hint_prefix"];
        }
        if (extra_props["hint_surfix"]) {
            hint_surfix = extra_props["hint_surfix"];
        }
        if (extra_props["hint_icon"]) {
            const iconMap = {
                "?": <QuestionCircleFilled />,
                question: <QuestionCircleFilled />,
                i: <InfoCircleFilled />,
                info: <InfoCircleFilled />,
                "!": <ExclamationCircleFilled />,
                exclamation: <ExclamationCircleFilled />,
                x: <CloseCircleFilled />,
                close: <CloseCircleFilled />,
                error: <CloseCircleFilled />,
                v: <CheckCircleFilled />,
                check: <CheckCircleFilled />,
                right: <CheckCircleFilled />,
                stop: <StopFilled />,
                warning: <WarningFilled />,
                $: <DollarCircleFilled />,
                usd: <DollarCircleFilled />,
                "￥": <PayCircleFilled />,
                cny: <PayCircleFilled />,
                file: <FileFilled />,
                json: <FileFilled />,
            };
            hint_icon = iconMap[extra_props["hint_icon"]] || (
                <InfoCircleFilled />
            );
        }
        return (
            <Tooltip
                placement="top"
                title={[
                    hint_prefix + hintString + hint_surfix,
                    "   ",
                    <CopyFilled
                        color="white"
                        onClick={() => {
                            navigator.clipboard.writeText(
                                prefix + fieldValue + surfix
                            );
                            message.info(
                                "已复制：" + prefix + fieldValue + surfix
                            );
                        }}
                    />,
                ]}
            >
                {prefix}
                {mainContent}
                {surfix}
                {hint_icon}
            </Tooltip>
        );
    } else {
        return (
            <span>
                {prefix}
                {mainContent}
                {surfix}
            </span>
        );
    }
}

function content(
    fieldValue,
    displayField,
    displayedItem,
    tintColor = undefined,
    nolink = false,
    actionExecutor = null,
    isDetail = false
) {
    if (!displayField.extra_props) {
        displayField.extra_props = {};
    }
    let { style, key, extra_props } = displayField;
    const rawValues = displayedItem._raws_ || displayedItem;
    const options =
        displayField.source && displayField.source.options
            ? displayField.source.options
            : undefined;
    let option =
        (fieldValue != undefined &&
            options &&
            options.reduce((p, c) => {
                if (
                    c.value === (rawValues[key] || fieldValue) ||
                    c.label === fieldValue
                )
                    return c;
                return p;
            }, undefined)) ||
        undefined;
    if (extra_props.hasOwnProperty("_badge_")) {
        let badgeColor = option ? option["extra_props"] : "default";
        let badegLabel = option ? option["label"] : fieldValue;

        if (extra_props._badge_ === "dot") {
            return <Badge color={badgeColor} text={badegLabel} />;
        } else if (extra_props._badge_ === "color") {
            return <Text type={badgeColor}>{badegLabel}</Text>;
        } else if (extra_props._badge_ === "stock") {
            return parseInt(badegLabel) > 0 ? (
                <Text type="success">{badegLabel}</Text>
            ) : (
                <Text type="danger">{badegLabel}</Text>
            );
        }
    } else if (tintColor) {
    }

    switch (style) {
        case "date":
            if (typeof fieldValue === "number") {
                fieldValue = moment(fieldValue * 1000).format("YYYY-MM-DD");
            }
            break;
        case "datetime":
            if (typeof fieldValue === "number") {
                fieldValue = moment(fieldValue * 1000).format(
                    "YYYY-MM-DD HH:mm"
                );
            }
            break;
        case "link":
            if (!fieldValue || fieldValue.length === 0) {
                return null;
            }
            if (typeof fieldValue === "string") {
                if (fieldValue.startsWith("/")) {
                    return (
                        <Link
                            to={{
                                pathname: fieldValue,
                                state: {
                                    title: displayField.title || fieldValue,
                                },
                            }}
                            key={"link_0"}
                        >
                            <span style={{ marginRight: 10, display: "block" }}>
                                {displayField.title || fieldValue || "<🈳>"}
                            </span>
                        </Link>
                    );
                } else {
                    return (
                        <span className={displayedItem.rowClassName}>
                            {fieldValue || "<🈳>"}
                        </span>
                    );
                }
            }
            if (typeof fieldValue === "number") {
                return (
                    <span className={displayedItem.rowClassName}>
                        {fieldValue}
                    </span>
                );
            }

            return extra_props["is_action"] && actionExecutor ? (
                renderActions(fieldValue, actionExecutor, {})
            ) : (
                <div>
                    {fieldValue.map((value, idx) => {
                        const { title, href } =
                            typeof value === "object" && value
                                ? value
                                : { title: value };
                        if (!href || nolink) {
                            return (
                                <span
                                    className={displayedItem.rowClassName}
                                    key={idx}
                                    style={{
                                        marginRight: 10,
                                        display: "block",
                                    }}
                                >
                                    {title || "<🈳>"}
                                </span>
                            );
                        }
                        return (
                            <Link
                                to={{ pathname: href, state: { title } }}
                                key={"link_" + idx}
                            >
                                <span
                                    style={{
                                        marginRight: 10,
                                        display: "block",
                                    }}
                                >
                                    {title || "<🈳>"}
                                </span>
                            </Link>
                        );
                    })}
                </div>
            );
        case "currency":
            if (
                parseFloat(fieldValue) === 0 ||
                !fieldValue ||
                fieldValue.length === 0
            ) {
                let zeroResult = "--";
                if (extra_props.hasOwnProperty("zero")) {
                    if (extra_props.zero == "_") zeroResult = "";
                    zeroResult = extra_props.zero;
                }
                return (
                    <span
                        className={displayedItem.rowClassName}
                        style={{
                            color: tintColor,
                            marginRight: 10,
                            display: "block",
                        }}
                    >
                        {zeroResult}
                    </span>
                );
            }

            let currencyType = extra_props.currency;
            if (!currencyType) {
                currencyType =
                    displayedItem.currency === "人民币" ||
                    displayedItem.currency === "cny" ||
                    key.indexOf("cny") > 0
                        ? "￥"
                        : displayedItem.currency === "美元" ||
                          displayedItem.currency === "usd" ||
                          key.indexOf("usd") > 0
                        ? "$"
                        : "￥";
            } else if (currencyType.startsWith(":")) {
                currencyType =
                    rawValues[currencyType.substring(1)] == 2 ? "$" : "￥";
            }
            let decimals = extra_props.decimals || 2;
            if (!extra_props.hasOwnProperty("decimals")) {
                const parts = ("" + fieldValue).split(".");
                if (parts.length > 1) {
                    const fraction = parts[1];
                    decimals = fraction.length;
                    while (fraction[decimals - 1] === "0") {
                        decimals -= 1;
                    }
                } else {
                    decimals = 0;
                }
            }

            const fm = new FormatMoney();
            return (
                <span
                    className={displayedItem.rowClassName}
                    style={{ color: tintColor, marginLeft: 5, marginRight: 5 }}
                >
                    {fm.from(+ fieldValue, {
                        symbol: currencyType,
                        decimals,
                    })}
                </span>
            );

        case "files":
            if (!fieldValue || fieldValue.length === 0) {
                return null;
            }
            if (typeof fieldValue == "string") {
                return (
                    <Text
                        className={displayedItem.rowClassName}
                        style={{ maxWidth: 50, backgroundColor: "transparent" }}
                        ellipsis
                    >
                        {fieldValue}
                    </Text>
                );
            }
            if (isArray(fieldValue)) {
                return <FileIcons files={fieldValue} />;
            }
            return "?";
        case "uri":
            if (!fieldValue || fieldValue.length === 0) {
                return null;
            }
            if (typeof fieldValue == "string") {
                return (
                    <a href={displayedItem[key]}>
                        {displayField.title || fieldValue}
                    </a>
                );
                // <Link to={{ pathname: fieldValue}} key={'link_0'}><span
                //     style={{ marginLeft: 10 }}>{displayField.title || fieldValue}</span></Link>
            }
            break;
        case "count":
            if (extra_props.zero && parseInt("" + fieldValue) === 0) {
                if (extra_props.zero === "_") return "";
                return (
                    <span style={{ color: tintColor || "#aaa" }}>
                        {extra_props.zero}
                    </span>
                );
            }

            if (
                fieldValue === undefined ||
                isNaN(parseInt("" + fieldValue)) ||
                parseInt("" + fieldValue) === 0
            ) {
                return <span style={{ color: tintColor || "#aaa" }}>--</span>;
            }

            return (
                <span
                    className={displayedItem.rowClassName}
                    style={{ color: tintColor, marginLeft: 5, marginRight: 5 }}
                >
                    {parseInt("" + fieldValue).toLocaleString("en")}
                </span>
            );

        case "json":
            if (isDetail) {
                try {
                    if (!fieldValue) {
                        return fieldValue;
                    }
                    let json = JSON.parse(fieldValue);
                    return (
                        <ReactJson
                            src={json}
                            theme="bright:inverted"
                            name={false}
                            collapsed
                            displayDataTypes={false}
                        />
                    );
                } catch (E) {
                    return (
                        <TextTruncate
                            line={extra_props.line || 10}
                            text={fieldValue}
                        />
                    );
                }
            } else {
                displayField.extra_props.ellipsis = true
                if (!fieldValue) return fieldValue;
                return (
                    <TextTruncate
                        style={{ display: "inline" }}
                        line={extra_props.line || 2}
                        text={fieldValue}
                    />
                );
            }

        default:
            break;
    }
    if (extra_props.ellipsis) {
        return (
            <Text
                className={displayedItem.rowClassName}
                style={{
                    color: tintColor,
                    maxWidth: 130,
                    backgroundColor: "transparent",
                }}
                ellipsis
            >
                {fieldValue}
            </Text>
        );
    }
    if (React.isValidElement(fieldValue)) return fieldValue;
    if (!fieldValue) {
        return extra_props.empty || extra_props.zero || "";
    }
    if (Array.isArray(fieldValue)) {
        return fieldValue.map((value, idx) => {
            const { title, href } =
                typeof value === "object" && value ? value : { title: value };
            return (
                <span
                    className={displayedItem.rowClassName}
                    key={idx}
                    style={{ color: tintColor, marginLeft: 5, marginRight: 5 }}
                >
                    {title}
                    <br />
                </span>
            );
        });
    }

    return (
        <span
            className={displayedItem.rowClassName}
            style={{ color: tintColor, marginLeft: 5, marginRight: 5 }}
        >
            {typeof fieldValue === "object"
                ? JSON.stringify(fieldValue)
                : fieldValue}
        </span>
    );
}

export {
    renderActions,
    renderItemFieldValue,
    renderBatchItemActions,
    renderActionWith,
};
