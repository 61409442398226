import React, {useContext, useEffect, useReducer, useState, useMemo, useCallback} from "react"
import TemplateDisplayTable from "../../components/df/TemplateDisplayTable"
import TemplateDisplayTree from "../../components/df/TemplateDisplayTree"
import {useHttpGet, useRouteQuery} from "../../core/hooks"
import AdminContext from "../../core/AdminContext"
import {useLocation, useHistory} from 'react-router-dom'
import {Button, Card, Input, Row, Spin} from "antd"
import PageHeaderWrapper from "../../components/PageHeaderWrapper"
import {renderActions} from "../../core/domUtil"
import SearchHeader from "../../components/df/SearchHeader"
import TableDisplay from "../../components/displays/TableDisplay"
import { isArray } from "lodash"

export default function DeviceBindedPage() {
    const {iotGroupId = 0, plusQingUid = 0, iotQingUid = 0} = useRouteQuery()
    const {pathname, } = useLocation()
    const {mac} = useRouteQuery();
    const history = useHistory();
    const [macText, setMacText] = useState(mac?.replace(/,/g, '\n') || '')
    const [queryObj, setQueryObj] = useState({mac: mac?.replace(/,$/g, '')})
    const [macResponse, getMacs] = useHttpGet('/device/getDevicesMacs', {iotGroupId, plusQingUid, iotQingUid})
    const [bindsResponse, getBinds] = useHttpGet('/device/loadDeviceBinds')
    
    if (macResponse?.loading) {
        return <div className="full-content-center">
            <Spin/>
        </div>
    }

    return <div>
        <PageHeaderWrapper />
        <div style={{margin: 40}}>
            <Input.TextArea
                value={macText}
                onChange={e => setMacText(e.target.value)}
                placeholder="请输入MAC地址，多个地址请换行"
                autoSize={{minRows: 3, maxRows: 6}}
                autoComplete='off'
                autoCapitalize="off"
                aria-autocomplete='none'
            />
            
            <Button disabled={!macText} type="primary"style={{margin: 20}} onClick={()=>{
                setQueryObj({mac: macText.trim().replace(/\n+/g, ',').replace(/:/g, '')})
            }}>查询</Button>
        </div>
        <div className="page-content-wrapper">
            <TableDisplay
                uri={queryObj.mac ? '/device/loadDeviceBinds': null}
                query={queryObj.mac ? queryObj : null}
                pagerDisabled={true}
            />
        </div>
    </div>
}
